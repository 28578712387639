import React, { useEffect } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";


import { getAnalytics, logEvent } from "firebase/analytics";

const Features = React.lazy(() => import("./Components/Features/Features"))
const About = React.lazy(() => import("./Components/About/About"))
const Footer = React.lazy(() => import("./Components/Footer/Footer"))
const YourMatch = React.lazy(() => import("./Components/YourMatch/Yourmatch"))

function Home() {
  const analytics = getAnalytics();
  useEffect(() => {
    logEvent(analytics, 'Page Loaded');

  }, []);

  return (
    <>
      <div className="Home">
        <Navbar />
        <Hero />
        <Features /> 
        <About />
        <YourMatch />
        <Footer />
      </div>
    </>
  );
}

export default Home;