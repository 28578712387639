import React from 'react';

const NoElementFound = () => {
    return (
        <div style={{ height: 200, width: "100vw",  display: 'flex', alignItems: 'center', justifyContent: "center"}}>
            <div style={{width: 200}}><h3>No Blogs Found</h3></div>
        </div>
    );
};

export default NoElementFound;
