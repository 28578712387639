import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./BlogCard.css";
import PropTypes from "prop-types";
import BlogArticle from "../../BlogArticle/BlogArticle";
import Skeleton from "react-loading-skeleton";

const BlogCard = ({ image, title, time, date, id, category, slug }) => {
  const [isLoading, setIsLoading] = useState(true);

  // Handler to track when the image has finished loading
  const handleImageLoad = () => {
    setIsLoading(false);
    console.log("id" + id);
  };
  const defaultImage = "";
  const placeholderImage = "/images/loader.svg";

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {BlogArticle ? (
        <div className="blog-card" onClick={handleClick}>
          <Link to={`/blogarticle/${slug}`} className="blog-card-link">
            <div className="img-container">
              {isLoading && (
                <img
                  className="blog-card-image-placeholder "
                  src={placeholderImage}
                  alt="placeholder"
                  width={200}
                  height={200}
                />
              )}
              <img
                className={`blog-card-image ${isLoading ? "hidden" : ""}`}
                src={image ? image : defaultImage}
                alt="blogcardbg"
                width={200}
                height={200}
                onLoad={handleImageLoad} // Set the onLoad event handler
              />
              <div className="categories">
                <p className="category">{category[0]}</p>
              </div>
            </div>
            <h2 className="blog-card-title">{title}</h2>
            <div className="blog-card-footer">
              <p className="blog-card-footer-text">{time}</p>
              <p className="blog-card-footer-text">{date}</p>
            </div>
          </Link>
        </div>
      ) : (
        <div className="blog-card" onClick={handleClick}>
          <Link to={`/blogarticle/${slug}`} className="blog-card-link">
            <div className="img-container">
              <Skeleton height={350} width={400} />
              <div className="categories">
                <Skeleton height={20} width={50} />
              </div>
            </div>
            <h2 className="blog-card-title">
              <Skeleton height={20} width={60} />
            </h2>
            <div className="blog-card-footer">
              <p className="blog-card-footer-text">
                <Skeleton height={20} width={30} />
              </p>
              <p className="blog-card-footer-text">
                <Skeleton height={20} width={30} />
              </p>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default BlogCard;

BlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  category: PropTypes.array.isRequired,
};
