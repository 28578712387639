import React from "react";
import BlogNavbar from "./Components/BlogNavbar/BlogNavbar";
import Footer from "./Components/Footer/Footer";
import BlogHero from "./Components/BlogHero/BlogHero";

const Blog = () => {
  return (
    <div className="Blog">
    <BlogNavbar/>
    <BlogHero/>
    <Footer />
    </div>
  );
};

export default Blog;
