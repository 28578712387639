import React, { useState, useEffect } from "react";
import "./BlogHero.css";
import BlogCard from "./BlogCard/BlogCard";
import { db } from "../../Firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import NoElementFound from "./NoElementFound";

const Blog = () => {
  const cardsPerPage = 6; // Number of cards to display per page
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCards, setFilteredCards] = useState([]);

  let unsubscribe;

  useEffect(() => {
    getBlogsMetaData();
    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset current page when list changes
  }, []);

  useEffect(() => {
    handleSearch(); // Update search results when searchQuery changes
  }, [searchQuery]);

  const updateCount = () => {
    // Implement your count update logic here
  };

  const getBlogsMetaData = () => {
    console.log("getBlogs meta data called")
    const q = query(
        collection(db, "blogMetaData"),
        where("published", "==", true),
        orderBy("lastUpdate", "desc")
    );

    unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newList = [];

      querySnapshot.docs.forEach(function (doc) {
        const data = doc.data();
        newList.push(data);
      });

      setList(newList);
    });
  };

  const handleSearch = () => {
    console.log("handleSearch called")
    const filteredList = list.filter(
        (blog) =>
            blog.title.toLowerCase().includes(searchQuery.toLowerCase().trim())
    );
    setFilteredCards(filteredList);
  };

  const handleSearchInputChange = (event) => {
    console.log("handleSearchInputChange called ", event.key)
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = searchQuery ? filteredCards : list.slice(startIndex, endIndex);

  const totalPages = Math.ceil((searchQuery ? filteredCards.length : list.length) / cardsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
      <div className="blog">
        <h1 className="bh-head">Welcome to our blog</h1>
        <div className="bh-subhead">
          <p className="bh-subhead-text">
            Where we share dating advice, relationship tips, and insights into the
            world of modern romance. Our blog will guide you through every step of
            your dating journey, from creating the perfect profile to planning
            unforgettable dates.
          </p>
        </div>
        <div className="search-section">
          <input
              type="text"
              placeholder="Search for your interest"
              className="search-input"
              onChange={handleSearchInputChange}
          />
          <button className="search-btn" onClick={handleSearch}>
            Search
          </button>
        </div>

        <div className="blogview-section">
          {/* <div className="bv-head">
          <p className="bv-head-title">All</p>
          <p className="bv-head-title">Popular</p>
          <p className="bv-head-title">Most Liked</p>
        </div> */}

          <div className="blogcard-wrapper">
            {currentCards.length > 0 ? (
                currentCards.map((blogMetaData) => (
                    <BlogCard
                        updateCount={updateCount}
                        key={blogMetaData.id}
                        id={blogMetaData.blogId}
                        image={blogMetaData.featureImage}
                        category={blogMetaData.categories.slice(0, 2)}
                        title={blogMetaData.title}
                        slug={blogMetaData.slug}
                        time={blogMetaData.lastUpdate.toDate().toLocaleTimeString()}
                        date={blogMetaData.lastUpdate.toDate().toLocaleDateString()}
                    />
                ))
            ) : (
                <NoElementFound />
            )}
          </div>

          <div className="pagenav-section">
            <div className="left-pagenav">
              <p className="left-pagenav-text">Total Pages: {totalPages}</p>
            </div>

            <div className="pagination">
              <a style={{ display: currentPage === 1 ? 'none' : '' }} href="#" onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</a>
              {pageNumbers
                  .filter((page) => page <= currentPage + 2 && page >= currentPage - 2)
                  .map((page) => (
                      <a
                          href="#"
                          key={page}
                          className={page === currentPage ? "active" : ""}
                          onClick={() => setCurrentPage(page)}
                      >
                        {page}
                      </a>
                  ))}
              <a style={{ display: currentPage === totalPages ? 'none' : '' }} href="#" onClick={() => setCurrentPage(currentPage + 1)}>
                &raquo;
              </a>
            </div>

            <div className="right-pagenav">
              <a className="right-pagenav-text" onClick={() => setCurrentPage(pageNumbers.length)}>
                Go to page: {pageNumbers.length}
              </a>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Blog;
