import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {collection, getDocs, initializeFirestore, persistentLocalCache, query} from "firebase/firestore";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBO2Eaoy2HtU5lWJP4T-PhSrgcnYnXAQBQ",
  authDomain: "findflamesapp.firebaseapp.com",
  projectId: "findflamesapp",
  storageBucket: "findflamesapp.appspot.com",
  messagingSenderId: "807607125271",
  appId: "1:807607125271:web:46486ab1681dbecf242352",
  measurementId: "G-8VE0BGDYP5"
};
// Initialize Firebase


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, {localCache: persistentLocalCache(/*settings*/{})});
const analytics = getAnalytics(app);

const get_slug = async () =>  {
  const docs = await getDocs(query(
      collection(db, "blogMetaData")));

  const p  = docs.docs.map((e) => e.data()["slug"]);

  console.log(JSON.stringify(p))

  return p
}



export { auth, app, db ,analytics, get_slug };

