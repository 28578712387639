import React from 'react'
import Footer from "../Footer/Footer";
import BlogContent from './BlogContent/BlogContent';
import BlogNavbar from '../BlogNavbar/BlogNavbar';
import {useParams} from "react-router";
 
const BlogArticle = () => {
    const { slug } = useParams();
  return (
    <div>
      <BlogNavbar />
      <BlogContent slug ={slug} />
      <Footer />
    </div>
  )
}

export default BlogArticle
