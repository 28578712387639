import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { analytics } from '../../Firebase';
import "./Navbar.css";
import { logEvent } from "firebase/analytics";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Listen for scroll event
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const heroSection = document.getElementById("hero-section");
      const heroSectionPosition = heroSection ? heroSection.offsetTop : 0;
      const featureSection = document.getElementById("features");
      const footerSectionPosition = featureSection
        ? featureSection.offsetTop
        : Infinity;
      const isScrolled =
        scrollPosition > heroSectionPosition &&
        scrollPosition < footerSectionPosition;
      setIsScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Listen for changes in screen size
  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth <= 768;
      setIsMobile(isSmallScreen && isMobile); // Only update if small screen and already mobile view
    };

    window.addEventListener("resize", handleResize);

    // Remove event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const navbarClass = `navbar ${isScrolled ? "scrolled" : ""}`;

  return (
    <nav className={navbarClass}>
      <div className="left-nav">
        <img src="/images/ff.svg" alt="logo" className="logo" />
        <h3 className="nav-text">
          <a href="/">Find Flames</a>
        </h3>
      </div>
      <ul
        className={isMobile ? "nav-links-mobile active" : "nav-links"}
        onClick={() => setIsMobile(false)}
      >
        <li className="item">
          <HashLink
            to="/#features"
            smooth={true}
            duration={300}
            className="item"
          >
            Features
          </HashLink>
        </li>
        <li className="item">
          <HashLink to="/blog" className="item">
            Blog
          </HashLink>
        </li>
        <li className="item">
          <HashLink to="/#aboutUs" smooth={true} duration={300} className="item">
            About
          </HashLink>
        </li>
        <li>
          <HashLink to="/#footer" smooth={true} duration={300} className="item">
            Contact us
          </HashLink>
        </li>
        <button className="get-btn" onClick={onPlayStoreButtonClicked}>
          <a href="https://play.google.com/store/apps/details?id=com.findflames.find_flames_app">
            Get the app
          </a>
        </button>
      </ul>
      <button
        className="hamburger-menu"
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? (
          <i className="fas fa-times"></i>
        ) : (
          <i className="fa-solid fa-bars"></i>
        )}
      </button>
    </nav>
  );
};


const onPlayStoreButtonClicked = () => {
  logEvent(analytics, "play-store-button-clicked")
}

export default Navbar;
