import React from "react";
import { Link } from "react-router-dom";
import "./Error404.css";

const Error404 = () => {
  return (
    <div className="error-404">
        <img src="/images/error.svg" alt="404" />
      <h1>Oops!</h1>
      <h2>The page you requested could not be found.</h2>
      <Link to="/" className="link-home">
        <button className="return-btn">Return Home</button>
      </Link>
    </div>
  );
};

export default Error404;
