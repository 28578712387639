import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Privacy from "./Components/Footer/Privacy.js";
import TermsPage from "./Components/Footer/Terms.js";
import Blog from "./Blog";
import BlogArticle from "./Components/BlogArticle/BlogArticle";
import Error404 from "./Components/Error404/Error404";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blogarticle/:slug" element={<BlogArticle />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
