import React, {useState} from "react";
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Footer.css";

const Footer = () => {
  const [email, setEmail] = useState("");

  const gmailLink = "mailto:support@findflames.com";
  const linkedinLink = "https://www.linkedin.com/company/findflames/";
  const instagramLink =
    "https://instagram.com/findflames?igshid=MzRlODBiNWFlZA==";

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribe = () => {
    // Validate email format
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const isValidEmail = emailRegex.test(email);

    if (isValidEmail) {
      // Email is valid, show success toast
      toast.success("Thank you for subscribing!");
    } else {
      // Invalid email, show error toast
      toast.error("Please enter a valid email address.");
    }

    setEmail("");
  };

  function onLinkClick() {
    // window.scrollTo(0, "instant")
  }

  return (
      <div id="footer" className="footer-section">
        <div className="footer-top">
          <div className="contact-info">
            <h1 className="contact-head">Contact info</h1>
            <ul className="contact-list">
              <li className="info-text">New Delhi, India</li>
              <li className="info-text">110047</li>
            </ul>
          <div className="social-icons">
            <a href={instagramLink} target="_blank" rel="noopener noreferrer">
              <img src="/images/insta.png" alt="insta" />
            </a>
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
              <img src="/images/linkedin.png" alt="linkedin" />
            </a>
            <a href={gmailLink} target="_blank" rel="noopener noreferrer">
              <img src="/images/gmail.png" alt="gmail" />
            </a>
          </div>
        </div>
        <div className="link-info">
          <h2 className="link-head">Important Links</h2>
          <ul className="link-list">
            <li>
              <Link to="/#features" className="link-text">
                Features
              </Link>
            </li>
            <li>
              <Link to="/blog" className="link-text" onClick={onLinkClick()}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/#aboutUs" className="link-text">
                About
              </Link>
            </li>
          </ul>
        </div>
        <div className="email-info">
          <h3 className="email-head">
            Stay informed about our monthly promotions & more
          </h3>
          <div className="email-sub">
            <input
                type="email"
                className="input-btn"
                placeholder="Enter your email"
                value={email}
                onChange={handleInputChange}

            />
            <button className="sub-btn" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <hr className="gap-line"></hr>

      <div className="footer-bottom">
        <ul className="policy-info">
          <li>
            <a
              href="/privacy"
              className="policy-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policies
            </a>
          </li>
          <li>
            <a
              href="/terms"
              className="policy-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </li>
        </ul>

        <ul className="copyright-info">
          <li className="copyright-text">Copyright &#169;2023,</li>
          <li className="copyright-text">All rights reserved</li>
        </ul>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Footer;
