import React from "react";
import "./Hero.css";

const Hero = () => {
  const gmailLink = "mailto:support@findflames.com";
  const linkedinLink = "https://www.linkedin.com/company/findflames/";
  const instagramLink =
    "https://instagram.com/findflames?igshid=MzRlODBiNWFlZA==";
  return (
    <div className="hero-section" id="hero-section">
      <div className="hero-head">
        <h1 className="head-text">Your Perfect Match is just a Click Away</h1>
      </div>
      <div className="hero-subhead">
        <h2 className="hero-subhead-text">
          Say goodbye to swiping endlessly and hello to meaningful connections
        </h2>
      </div>
      <button className="cta">
        <a
          href="https://play.google.com/store/apps/details?id=com.findflames.find_flames_app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get the app
        </a>
      </button>

      <div className="social-icons space" >
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <img src="/images/insta.png" alt="insta" />
        </a>
        <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
          <img src="/images/linkedin.png" alt="linkedin" />
        </a>
        <a href={gmailLink} target="_blank" rel="noopener noreferrer">
          <img src="/images/gmail.png" alt="gmail" />
        </a>
      </div>
      <div className="user-image">
        <img
            src="/images/male.svg"
            alt="Male"
            className="male-pic"
        />
        <img
          src="/images/female.svg"
          alt="Female"
          className="female-pic"
        />
      </div>
    </div>
  );
};

export default Hero;
